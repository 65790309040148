// Commenting code for shorter token expiration time
// import axios from "axios";

export default function authHeader(){
    let user = JSON.parse(localStorage.getItem('user'));

    // let base64Url = user.token.split('.')[1];
    // let base64 = base64Url.replace('-', '+').replace('_', '/');
    // const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    // }).join(''));
    // const decodedPayload = JSON.parse(jsonPayload);

    // let exp = decodedPayload.exp;
    // let now = Date.now();
    // let nowInSeconds = Math.round(now / 1000);
    // let difference = exp - nowInSeconds;
    // let differenceInMinutes = Math.round(difference / 60);

    // if (differenceInMinutes <= 5) {
    //     console.log('Token is about to expire, refreshing token');
    //     checkTokenExpiration(user);
    // }

    if(user && user.token){
        return {Authorization: 'Bearer ' + user.token, 'x-vars-version': 2}
    }else{
        return {};
    }
}

// async function checkTokenExpiration(user){
//     const response = await axios.post('https://api.varsanpr.com/api/auth/refresh', {}, {
//         headers: {
//             'x-vars-version': 2,
//             "x-vars-refresh-token": user.refreshToken,
//             Authorization: 'Bearer ' + user.token,
//         }
//     });
//     if (response.data.refreshToken) {
//         user.refreshToken = response.data.refreshToken;
//     }
//     if (response.data.accessToken) {
//         user.token = response.data.accessToken;
//     }
//     localStorage.setItem('user', JSON.stringify(user));
// };